import { IndexedDataPhoto } from '../types/Modified';
import { SmpDataAlbum, SmpDataPhoto } from '../types/SmpData';

export const getAlbumRoute = (album: SmpDataAlbum) => `/view/${album.name}`;
export const getPhotoRoute = (album: SmpDataAlbum, photo: SmpDataPhoto) => {
  const index = album.photos.indexOf(photo);
  return `/view/${album.name}/${index}`;
};
export const getIndexedPhotoRoute = (album: SmpDataAlbum, photo: IndexedDataPhoto) => `/view/${album.name}/${photo.albumIndex + 1}`;
export const getPhotoRouteFromIndex = (album: SmpDataAlbum, photoIndex: number) => `/view/${album.name}/${photoIndex}`;
