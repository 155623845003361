import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ReactGA from 'react-ga';
import DataContext from '../../data/DataContext';

export const visitedPages = [];

ReactGA.initialize('UA-24575380-13');

const usePageViews = () => {
  const location = useLocation();
  const { data } = useContext(DataContext);

  const tracker = data?.token_info?.tag || 'REGULAR';

  useEffect(() => {
    ReactGA.pageview(tracker + '__' + location.pathname);
    visitedPages.push(location.pathname);
  }, [location, tracker]);
};
const HistoryLogger = () => {
  usePageViews();
  return <></>;
};

export default HistoryLogger;
