import { useHistory } from 'react-router-dom';
import { visitedPages } from '../pages/RootPage/HistoryLogger';

export const useTimeMachine = () => {
  const history = useHistory();

  // Note: tracking the visited pages is sloppy
  const rewindTo = (pagePath: string) => {
    if (visitedPages.includes(pagePath)) {
      const lastIndex = visitedPages.lastIndexOf(pagePath);
      const goAmount = lastIndex - visitedPages.length + 1;
      visitedPages.splice(lastIndex, visitedPages.length);
      history.go(goAmount);
    } else {
      history.push(pagePath);
    }
  };

  const push = (pagePath: string) => {
    history.push(pagePath);
  };

  return { rewindTo, push, history };
};
