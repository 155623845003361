import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},

  topSection: {
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.25em',
  },
  topNav: {
    '--btn-size': '5em',
    display: 'flex',
    flex: '0 0 calc(var(--btn-size) + 1em)',
    margin: '0.5em 0',

    '@media (max-width: 799px)': {
      '--btn-size': '3em',
    },
    '@media (max-width: 599px)': {
      '--btn-size': '2em',
    },
  },
  backBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'var(--btn-size)',
    // height: 'var(--btn-size)',
    border: 'none',
    color: 'var(--primary)',
    cursor: 'pointer',

    background: 'rgba(255, 255, 255, 0)',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.05)',
    },

    '& > svg': {
      fontSize: '2em',
    },
  },

  albumInfo: {
    display: 'flex',
    flexFlow: 'column nowrap',
    maxWidth: 500,
    margin: '1em auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoBox: {
    padding: '0.2rem 0.5rem',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
  },
  date: {
    marginTop: '0.25em',
  },
  posted: {
    color: 'rgba(var(--primary-rgb), 0.75)',
  },
  links: {
    flexFlow: 'row wrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instagram: {
    '& a:hover': {
      color: 'var(--instagram)',
    },
  },
  email: {
    '& a:hover': {
      color: 'var(--email)',
    },
  },
  link: {
    '& a:hover': {
      color: 'var(--website)',
    },
  },
  description: {
    display: 'block',
    width: '100%',
    opacity: 0.7,
  },
  patreon: {
    display: 'block',
    backgroundColor: 'var(--patreon)',
    margin: '0.5em',
    '& a': {
      display: 'inline-block',
      color: 'var(--patreon-black)',
      padding: '1em',
    },
    '& a:hover': {
      color: 'rgba(var(--patreon-black-rgb), 0.8)',
    },
  },
  notice: {
    fontSize: '0.5em',
    backgroundColor: '#FF413688',
    padding: '0.25em 0.5em',
    borderRadius: '0.5em',
  },
  sisterAlbums: {
    margin: '1em auto',
    width: '100%',
    maxWidth: 'calc(3 * 12em)',
  },
  sisterAlbumsGrid: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    marginTop: '0.2em',

    '& > a': {
      width: `${100 / 3}%`,
      minWidth: '8em',
    },
  },
}));

export default useStyles;
